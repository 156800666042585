// mockで記述されたJSをここに書く
export const enableScriptsFromMock = () => {
  $(function () {
    /*
     ** ヘッダーをスクロールで隠す
     */
    // ヘッダーを取得
    const header = $("header");
    const pageTitleArea = $(".pageTitleArea");
    // ヘッダーの高さを取得
    const hH = header.outerHeight() + pageTitleArea.outerHeight();
    // 現在地
    let pos = 0;
    // スクロール直前の位置
    let lastPos = 0;
    // スクロール位置がヘッダーの高さ分より大きい場合、もしくはスクロールが進んだ場合に、classを付与
    const onScroll = () => {
      if (pos < hH) {
        header.removeClass("is_hide");
        pageTitleArea.removeClass("is_hide");
        return;
      }
      if (pos > lastPos && pos > 50) {
        header.addClass("is_hide");
        pageTitleArea.addClass("is_hide");
      } else {
        header.removeClass("is_hide");
        pageTitleArea.removeClass("is_hide");
      }
      // 最後のスクロール位置を保存
      lastPos = pos;
    };
    window.addEventListener("scroll", () => {
      // スマホ時でメニューが開いていたら処理を中止
      if (header.hasClass("is_open")) {
        return;
      }
      // スクロールするごとに現在地を更新
      pos = window.scrollY;
      onScroll();
    });

    /*
     ** PC時のヘッダーメニューの開閉
     */
    $(".js_showPCMenu").on("click", function () {
      header.toggleClass("is_open");
    });

    /*
     ** スマホ時のヘッダーメニューの開閉
     */
    $(".js_toggle_menuSP").on("click", function () {
      $("header").scrollTop(0);
      header.toggleClass("is_open");
    });

    /*
     ** ヘッダー以外の場所をクリックで、ヘッダーメニューを閉じる
     */
    $(document).on("click", function (e) {
      if (!$(e.target).closest("header").length) {
        header.removeClass("is_open");
      }
    });

    /*
     ** 公式オフィスのボタンクリックでローディング表示
     */
    $(".officeItem").on("click", function () {
      $(this).addClass("is_active");
    });

    /*
     ** ページトップに戻るの制御
     */
    const toPageTop = $("#toPageTop");
    // クリック時の処理
    toPageTop.on("click", function () {
      // ページトップにページ内移動する
      $("body,html").animate(
        {
          scrollTop: 0
        },
        300
      );
      return false;
    });
    // スクロール時の処理
    $(window).on("scroll", function () {
      // スクロールを進めると、ページトップに戻るボタンが現れる
      if ($(window).scrollTop() <= 500) {
        toPageTop.removeClass("is_show");
      } else {
        toPageTop.addClass("is_show");
      }
      // フッター上でページトップに戻るボタンをストップ
      const scrollHeight = $(document).height();
      const scrollPosition = $(window).height() + $(window).scrollTop();
      const footHeight = $("footer").innerHeight();
      if (scrollHeight - scrollPosition <= footHeight) {
        toPageTop.css({
          position: "absolute",
          bottom: footHeight + 10
        });
      } else {
        toPageTop.css({
          position: "fixed",
          bottom: "10px"
        });
      }
    });

    /*
     ** スマホ時モーダル内の、「この日の空き状況」部分の開閉は、app/javascript/pages/shared/DayReservationsStatusTable.vueで記述
     */

    /*
     ** お知らせモーダルを開いた時に、モーダル内スクロールを上に戻す
     */
    $('[data-target="#infoBarContentModal"]').on("click", function () {
      setTimeout(function () {
        $(".infoBarContentModal .modal-body").scrollTop(0);
      }, 500);
    });
  });
};
